import React from 'react'
import About from './About'
import AsSeenAs from './AsSeenAs'
// import Collateral from './Collateral'
import Goals from './Goals'
import Howitworks from './Howitworks'
import Roadmap from './Roadmap'
import Supply from './Supply'
import Team from './Team'
import Token from './Token'

const Main = () => {
  return (
    <main>
      <Token />
      <Supply />
      {/* <Collateral /> */}
      <Howitworks />
      <Roadmap />
      <Goals />
      <About />
      <Team />
      <AsSeenAs />
    </main>
  )
}

export default Main
