import React from 'react';
import './App.scss';
import Footer from './components/Footer';
import Main from './components/Main';
// import Navbar from './components/Navbar';
// import { FaLongArrowAltUp } from "react-icons/fa"
import Header, { DefaultBtn } from './components/Header';
import styled from 'styled-components';
import Navbar from './components/Navbar';
import { Redirect, Route, Switch } from 'react-router';
// import Privacy from './components/Footer/Privacy';



const Modal = styled.div`
  background-color: rgba(0,0,0, 0.5);
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  display: none;

`;

function App() {
  return (
    <div className="App relative">
      <Navbar />
      <div>


        <Switch className="mt-16">
          <Route exact path="/" >
            <Header />
            <Main />
          </Route>
          <Redirect to ="/" />
        </Switch>
      </div>
      <Footer />


      {/* Modal */}
      <Modal>
        <section className="bg-white p-8">
          <h2>Join our Mailing list now!!!</h2>
          <form>
            <input type="email" placeholder="example@xyz.com" />
          </form>
          <DefaultBtn>Subscribe</DefaultBtn>
        </section>
      </Modal>

      {/* <Navbar1 /> */}
      {/* <div>
        <FaLongArrowAltUp />
      </div> */}
    </div>
  );
}

export default App;
