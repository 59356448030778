import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import iMac1 from "../../images/iMac1.png"
import iMac2 from "../../images/iMac2.png"
import iMac3 from "../../images/iMac3.png"

import SDG1 from "../../images/SDG1.png"
import SDG2 from "../../images/SDG2.png"
import SDG3 from "../../images/SDG3.png"
import SDG4 from "../../images/SDG4.png"
import SDG5 from "../../images/SDG5.png"
import SDG6 from "../../images/SDG6.png"
import SDG7 from "../../images/SDG7.png"
import SDG8 from "../../images/SDG8.png"

import whitepaper from "../../images/whitepaper.jpeg"
import mobile from "../../images/mobile.jpeg"

const HolderDiv = styled.section`
    position: relative;
    background: url(${props => props.bgImage});
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 2rem;
    /* margin-top: 3rem; */
    /* justify-content: center; */
    align-items: center;
    background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    


    

  .modal{
    background-color: rgba(0,0,0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

  }
  `;

const AbsoluteText = styled.h3`
    position: relative;
    margin: 0;
    font-family: 'Lora', serif;
    z-index: 20;
    font-size: 30px;
    max-width: 80%;
    font-weight: bold;
    @media (min-width: 768px) { 
    font-size: 52px;
      width: 573px;
    }
  `;
const ImageHolder = styled.div`
    img{
    margin: auto;
    }
  `;

const TheDiv = styled.div`
    .desktop{
      display: block;
    }
    .mobile{
      display: none;
    }

    @media (max-width: 640px) { 
    .desktop{
      display: none;
    }
    .mobile{
      display: block;
    }
     }
  `;
const Carousel = () => {

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    fade: true,
    adaptiveHeight: true,
    autoplaySpeed: 4000,
    className: "text-white",
    cssEase: "linear"
  };





  return (
    // <div>
    <Slider {...settings}>
      <div>
        <HolderDiv bgImage={iMac1}>
          <div className="modal"></div>
          <AbsoluteText className="">$SHARE Token: Governance & Protocol token of the Easyshare ecosystem</AbsoluteText>
        </HolderDiv>
      </div>
      <div>

        <HolderDiv bgImage={iMac2}>
          <div className="modal"></div>
          {/* <img src={iMac2} alt="Carousel 2" /> */}
          <AbsoluteText className="mx-auto container w-11/12">Hodlers stake $SHARE to earn rewards, propose or vote for new protocol upgrades</AbsoluteText>

        </HolderDiv>
      </div>

      <div>
        <HolderDiv bgImage={iMac3}>
          <div className="modal"></div>
          <AbsoluteText className="mx-auto container w-11/12">$SHAREHodler agents use $SHARE as collateral, when KYCed.</AbsoluteText>
        </HolderDiv>
      </div>

      <div>
        <div className="container md:mt-16 mx-auto py-16 flex items-center flex-col lg:flex-row">

          <div className="w-full lg:w-6/12 lg:pr-6 text-black">
            <h2 className="text-2xl md:text-4xl font-semibold">Our Contribution to SDGs</h2>
            <p>Financial inclusion models such as the Easyshare ecosystem can help support overall
              economic growth and the achievement of broader development goals.
            </p>
          </div>
          <ImageHolder className="grid gap-4 grid-cols-2 sm:grid-cols-4">
            <img src={SDG1} alt="sustainable development goals 1" />
            <img src={SDG2} alt="sustainable development goals 2" />
            <img src={SDG3} alt="sustainable development goals 4" />
            <img src={SDG4} alt="sustainable development goals 5" />
            <img src={SDG5} alt="sustainable development goals 8" />
            <img src={SDG6} alt="sustainable development goals 9" />
            <img src={SDG7} alt="sustainable development goals 10" />
            <img src={SDG8} alt="sustainable development goals 17" />
          </ImageHolder>
        </div>
      </div>

      <TheDiv className="mt-20">
        <a href="https://token.easyshare.africa/Easyshare_SHARE_Whitepaper_Public.pdf" rel="noopener noreferrer" target="_blank">
          <img src={whitepaper} alt="white paper" className="desktop w-full h-screen object-cover" />
          <img src={mobile} alt="white paper" className="mobile w-full h-screen object-cover" />
        </a>
      </TheDiv>


    </Slider>
  );
}


export default Carousel