import React from 'react'
import { Heading, SmallerHead } from './About'
import TokenImg from "../../images/blank.png"
import Star from "../../images/star.svg"
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();
const Token = () => {
  return (
    <div id="token" className="container mx-auto py-16 overflow-hidden">

      <div className="flex flex-row-reverse items-start justify-between">
        <img data-aos="zoom-out-left" data-aos-duration="1500"
          src={TokenImg} alt="token" className="hidden lg:block w-3/12" />
        <div className="lg:w-8/12 flex items-start">
          <img data-aos="zoom-out-left" data-aos-duration="1500"
            src={Star} alt="token" className="mr-8 hidden md:block" />
          <div className="">
            <Heading data-aos="fade-right" data-aos-duration="1500">Token</Heading>
            <p data-aos="fade-up-right" data-aos-duration="2000">The $SHARE Token is a Governance token that will serve as the Protocol token of
            the Easyshare ecosystem, where Hodlers can use it to Stake to earn rewards,
            propose or vote for new protocol technology changes and upgrades, and also use it
            as collateral, when subscribed as a $ShareHodler Agents, to distribute remittances in
            cryptocurrency or local fiat currency.<br /> There will be an initial 250,000 $SHARE tokens
            minted and 20% will be made available for both private and public sale.</p>
            <SmallerHead className="mt-5"
              data-aos="fade-up-right" data-aos-duration="2500">$SHAREconomics</SmallerHead>
            <p data-aos="fade-up-right" data-aos-duration="3000">The $SHARE token will initially be available to $ShareHodler Agents and angel Investors in a whitelist IDO Token sale,
            followed by an exchange public listing sale.
            We have identified specific countries with the highest volume of crypto use as pilot sites to sign up remittance agents for the beta pilots.
          </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Token
