import React from 'react'
import { Heading } from '../Main/About'
// import { TheList } from './Modal'

const Terms = () => {
  return (
    <div>
      <Heading>Terms and Conditions</Heading>
      <p>
        Welcome to Token.easyshare.africa herein referred to as Easyshare. If you continue to browse and use Easyshare, you are agreeing to comply with and be bound by the following terms and conditions of use, which, together with our privacy policy, govern Easyshare’s relationship with you in relation to all our applications and platforms. If you disagree with any part of these terms and conditions, please do not use any of our applications and platforms.
      </p>
      <p>
        The terms ‘Token.easyshare.africa’ or ‘us’ or ‘we’ refer to the owner/s of Easyshare. The use of Easyshare is subject to the following terms of use:
      </p>
      <p>
        The content of the pages of Easyshare is for your general information and use only. It is subject to change without notice.
      </p>
      <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on Token.easyshare.africa for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
      <p>Your use of any information or materials on Easyshare is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through Easyshare meet your specific requirements.</p>
      <p>Easyshare platforms contain material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
      <p>All trademarks reproduced on Easyshare, which are not the property of, or licensed to the operator, are acknowledged on our applications and platforms. Unauthorised use of Easyshare may give rise to a claim for damages and/or be a criminal offence.</p>
      <p>From time to time, Easyshare applications and platforms may also include links to other websites, applications and/or platforms. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s), application(s) and/or platform(s). We have no responsibility for the content of the linked website(s), application(s) and/or platform(s).<br />
Easyshare reserves the right to make changes to our website, applications and/or platforms or any materials related to Easyshare without notice.</p>
    </div>
  )
}

export default Terms
