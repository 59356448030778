import React from 'react'
import styled from 'styled-components'
import Carousel from './Carousel';

export const DefaultBtn = styled.button`
      background: #33d5ba;
    border-radius: 4px;
    color: #fff;
    text-transform: capitalize;
    padding: 16px 13px;
    /* transition: 0, */
    transition-duration: 0.5s;
    width: 200px;
    margin: .5rem;

    @media (max-width: 768px) { 
      width: 100%;
     }

    &:hover{
      transform: scale(1.05);
    }
`;

const Header = () => {
  return (
    <header className=" ">

      <div className="">
        <Carousel />
      </div>
    </header>
  )
}

export default Header
