import React from 'react'
import { CenterLine, LighterDiv } from './About'
import '../../Footer.scss'
import styled from 'styled-components';



const BorderDiv = styled.p`
  border: dashed 5px ${props => props.inputColor || "rgba(109, 196, 242, 0.5)"};
  padding: 1.5rem;
  @media (max-width: 768px) { 
    padding: 1rem;
  }
`;


const Howitworks = () => {
  return (
    <LighterDiv>

      <div id="how" className="container mx-auto py-16 ">
        <div className="mx-auto text-center">
          <CenterLine className="text-center ">How It Works</CenterLine>
        </div>
        <div className="grid gap-8 lg:grid-cols-3 my-6">
          <BorderDiv>KYC’d and verified crypto remittance agents ($SHAREHodlers) and angel Investors will be responsible for Peer to Peer
          remittances and payouts in their local markets.
          $SHAREHodlers will be required to stake $SHARE tokens which will act as Collateral insurance for
      payouts in destination country.</BorderDiv>
          <BorderDiv inputColor="rgba(255, 209, 60, 0.5)">
            $SHAREHodlers are granted a mobile app admin panel to process payout transactions locally
            and will have a transaction limit equal to half of their staked $SHARE token.
            If for any reason a $SHAREHodler defaults on processing payout transactions to recipients,
            their staked $SHARE tokens will be liquidated to pay out any pending end user remittances.
      </BorderDiv>
          <BorderDiv inputColor="rgba(51, 213, 186, 0.51)">
            Any remaining $SHARE from the default goes back to the $SHAREHodler after deduction of a 50%
            penalty fee. This is to deter $SHAREHodlers from defaulting on settling transactions
            in-countries within 1-3 business days, and to create a trusted KYC’d peer to peer payout system.
         </BorderDiv>
        </div>

      </div>
    </LighterDiv>
  )
}

export default Howitworks
