import React from 'react'
import { Heading } from './About'
import Star from "../../images/star.svg"
import ChartFile from './ChartFile'
// import ChartsP from './ChartsP'

const Supply = () => {
  return (

    <div id="supply" className="container mx-auto py-16 flex items-start">
      <img data-aos="zoom-out-left" data-aos-duration="1500"
        src={Star} alt="token" className="mr-8 hidden md:block" />
      <div className="w-full">
        <Heading className="mb-4">Total Supply</Heading>
        {/* <p>We have a total supply of 250,000 $SHARE and the Private sale rate is $5 per $SHARE Token.</p> */}
        <ChartFile />
        {/* <ChartsP /> */}
        {/* <ul className="pl-8 list-disc">
          
          <li>10% of the total supply is available for purchase at IDO whitelist sale </li>
          <li>10% of the total supply is available for purchase at public sale listing</li>
          <li>10% supply will be reserved for $SHAREHodler Staking</li>
          <li>20% - Easyshare Community Reserve & Grants </li>
          <li>10% - Team allocation  </li>
          <li>10% - Marketing  </li>
          <li>5% - Advisors  </li>
          <li>5% - Partners </li>
          <li>20% - Liquidity  </li>
        </ul> */}
      </div>
    </div>
  )
}

export default Supply
