import React from 'react'
import { Heading, LighterDiv } from './About'
import Ben from "../../images/team/ben.jpg"
import Bamidele from "../../images/team/bamidelee.jpg"
import Gugu from "../../images/team/gugu.jpg"
import Prince from "../../images/team/prnce.jpeg"
import Lucky from "../../images/team/Lucky.jpeg"
import Soji from "../../images/team/Soji.jpeg"
import Boulevard from "../../images/team/Boulevard.jpg"
import Daniel from "../../images/team/Daniel.jpeg"
import styled from 'styled-components'
import { IoLogoLinkedin } from "react-icons/io"


const H2 = styled.h2`
  font-size: 1.35rem;
  font-weight: 500;
  padding: .75rem 1rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  background-color: #33d5ba;
  color: white;
  span{

  font-weight: 800;
  }
  & + div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 0 0 1rem;
    h3{
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    a{
      font-size: 2.5rem;
      color: #0a66c2;
      cursor: pointer;
    }
  }
  `;


const TeamArray = [
  {
    firstName: "Ben",
    surname: "Onuoha",
    position: "Partnerships & Community",
    linkedin: "https://www.linkedin.com/in/benonuoha/",
    image: Ben
  },
  {
    firstName: "Gugulethu",
    surname: "Nyathi",
    position: "Operations",
    linkedin: "https://www.linkedin.com/in/gugu-newman-nyathi-11ab17a7/",
    image: Gugu
  },
  {
    firstName: "Bamidele",
    surname: "Alegbe",
    position: "Tech",
    linkedin: "https://www.linkedin.com/in/bamidelealegbe/",
    image: Bamidele
  },
  {
    firstName: "Prince Ugomike",
    surname: "Enwerem",
    position: "Marketing & BizDev",
    linkedin: "https://www.linkedin.com/in/ugo-mike-enwerem-7aa92561/",
    image: Prince
  },
  {
    firstName: "Lucky", 
    surname: "Uwakwe",
    position: "Lead Strategist and Head Advisory",
    linkedin: "https://www.linkedin.com/in/lucky-uwakwe-a66949b7/",
    image: Lucky
  },
  {
    firstName: "Soji",
    surname: "Akinlabi",
    position: "Strategic Advisor",
    linkedin: "https://www.linkedin.com/in/sojiakinlabi/",
    image: Soji
  },
  {
    firstName: "Boulevard A",
    surname: "Aladetoyinbo",
    position: "Blockchain Legal and Strategy",
    linkedin: "https://www.linkedin.com/in/boulevard-a-aladetoyinbo-esq-a864a9123/",
    image: Boulevard
  },
  {
    firstName: "Daniel",
    surname: "Bloch",
    position: "Advisor",
    linkedin: "https://www.linkedin.com/in/daniel-bloch-46753011b/",
    image: Daniel
  },

]

const Team = () => {
  return (
    <LighterDiv>

      <div className="container mx-auto py-16">
        <Heading>Team</Heading>
        <p>Meet the team</p>
        <div className=" sm:px-16 md:px-0 grid   md:grid-cols-2 lg:grid-cols-4 gap-8">

          {
            TeamArray.map(person => (
              <div key={person.firstName}>
                <img className="w-full" src={person.image} alt={person.firstName} />
                <H2>{person.firstName} <span className="inline md:block">{person.surname}</span></H2>
                <div>
                  <h3 className="capitalize">{person.position}</h3>
                  <a rel="noopener noreferrer" target="_blank" href={`${person.linkedin}`}>
                    <IoLogoLinkedin />
                  </a>
                </div>
              </div>
            ))
          }

        </div>
      </div>
    </LighterDiv>
  )
}

export default Team
