import React from 'react'
import styled from 'styled-components'
import AboutSVG from "../../images/about.svg"

const ImgAbsolute = styled.img`
  top: -36px;
  right: -36px;


  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const LighterDiv = styled.div`
  background-color:  #ffffffb8;
`;

export const Heading = styled.h1`
  font-size: 1.75rem;
  font-weight: 600;
  position: relative;
  display: inline-block;
  z-index: 10;
  &::after {
      content: '';
      position: absolute;
      width: 80%;
      height: 4px;
      left: 0;
      right: 0;
      margin: 0;
      bottom: 2px;
      background-color: #33d5bf;
      z-index: -1;
    }
`;

export const CenterLine = styled(Heading)`
  &::after {
      width: 80%;     
  
      margin: auto;
    }
`;

export const SmallerHead = styled(Heading)`
  font-size: 1.75rem;
`;

const About = () => {
  return (
    // <LighterDiv >
    <div id="about" className="text-center container mx-auto py-16  flex items-center flex-col-reverse lg:flex-row-reverse ">
      <div className="w-full lg:w-10/12 mx-auto bg-white p-8 relative">
      <ImgAbsolute className="absolute w-24"
       src={AboutSVG} alt="about tick" />
        <div className="text-center">
          <CenterLine className="">About Us</CenterLine>
        </div>
        <p className="mt-4">Easyshare is a secure decentralized, collateralized and tokenized peer to peer remittance protocol that
        adds value to the global remittance market by solving the last mile infrastructure,
        trust and liquidity problems in remittance payments in emerging economies.
          </p>
        <p className="mt-4">The Easyshare Team & Advisory are a collective of
        decentralized individuals with years of experience in Blockchain, Dapp Development,
        Cryptocurrencies, Banking, Financial Services, Entrepreneurship Ecosystems, Sales,
        Marketing, Blockchain development, Software development, Product development  and Community development.
          </p>
        <p className="mt-4">We are truly committed to using our
        collective skills and experience to deliver Blockchain’s philosophical
        promise of decentralized financial services and socioeconomic participation to
        underserved communities.
          </p>
      </div>
      {/* <img src=e{AboutImg} alt="the about" className="md:w-9/12 lg:w-6/12 mb-6 lg:mb-0" /> */}
    </div>
    // </LighterDiv>
  )
}

export default About
