import React from 'react'
import styled from 'styled-components';
import { CenterLine } from './About'
import ParallaxImg from "../../images/roadmap.png"
import WavyLevel from "../../images/wavy.svg"

const RoadUl = styled.ul`
  li{
    &:first-of-type{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 23px;
    margin-bottom: .25rem;
  }
}

  /*

  &::after{
    content: "";
    position: absolute;
    top: 50%;
    right: -13.75px;
    border: 10px solid #33d5ba;
    border-radius: 50%;
  }
  


  @media (max-width: 768px) { 
    border-right: 0;
    border-left: 6px solid #33d5ba7a;
    width: 100%;
    padding-left: 1.5rem;
    &::after{
    left: -13.75px;
    right: auto;
  }
    &:nth-of-type(even){
    margin-left: auto;
    margin-right: auto;
   }
   } */
`;

const RoadDiv = styled.div`
  position: relative;
  padding-left: 1.25rem;
  margin-right: auto;
  border-right: 0 !important;
  border-top: 0 !important;
  border-width: 6px;
  border-style: solid;
  border-image: 
    linear-gradient(
      to bottom, 
      #33d5ba, 
      rgba(0, 0, 0, 0)
    ) 1 80%;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: -13px;
    border: 10px solid #33d5ba;
    border-radius: 50%;
  }
  h2{
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1.25rem;
    span{
      color: #33d5ba;
      font-size: 2.5rem;
    }
  }
  


`;

const ParallaxText = styled.div`
  background-image: url(${ParallaxImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .modal{
    position:absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.8)
  }
`;

const Roadmap = () => {
  return (
    // <LighterDiv >

    <div className="py-16" id="roadmap">
      <ParallaxText className="relative text-white py-8">
        <div className="container text-center mx-auto">
          <CenterLine className="mb-8">2021 RoadMap</CenterLine>
        </div>

        <div className="modal"></div>
        <div className="container mt-12 mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <RoadDiv>
            <h2><span>Q1</span> 2021</h2>
            <RoadUl>
              <li>concept</li>
              <li>Research</li>
              <li>Ideation and wireframe development</li>
              <li>Team formation</li>
              <li>Team expansion</li>
              <li>Responsive mobile and web app development</li>
              <li>App soft launch</li>
              <li>Beta testing</li>
            </RoadUl>
          </RoadDiv>
          <RoadDiv>
            <h2><span>Q2</span> 2021</h2>
            <RoadUl>
              <li>Easyshare 1.0 Beta App launch</li>
              <li>Smart contract and token</li>
              <li>Whitepaper</li>
              <li>Token sale</li>
              <li>Dex & Exchange listing</li>
              <li>Tokenization</li>
              <li>Easyshare Staking Program, airdrop and token distribution</li>
              {/* <li>Buy back and burn program setup </li> */}
              <li>Team expansion</li>
            </RoadUl>
          </RoadDiv>

          <RoadDiv>
            <h2><span>Q3</span> 2021</h2>
            <RoadUl>
              <li>scaling</li>
              <li>Marketing, media and community engagement</li>
              <li>More Exchange Listing</li>
              <li>Regulatory research</li>
              <li>Wallet 1.0 launch</li>
              <li>Crypto 101 online series launch</li>
              {/* <li>Buy back and burn program</li> */}
              <li>Team expansion</li>
            </RoadUl>
          </RoadDiv>

          <RoadDiv>
            <h2><span>Q4</span> 2021</h2>
            <RoadUl>
              <li>Community</li>
              <li>Marketing, media and community engagement</li>
              <li>Community hackathon</li>
              <li>Airdrops and token distribution</li>
              <li>Community partnerships</li>
              <li>Marketplace 1.0 launch</li>
              <li>Crypto 101 online events</li>
              {/* <li>Buy back and burn program</li> */}
            </RoadUl>
          </RoadDiv>
        </div>
        <p className="text-white container mx-auto relative list-disc z-50 text-center text-2xl my-6">A detailed 5 year roadmap will be unveiled in Q4 2021</p>
        <img src={WavyLevel} alt="wavy level" className="z-10 relative w-full" />
      </ParallaxText>
    </div>
    // </LighterDiv>
  )
}

export default Roadmap
