import React from 'react'
import '../../Footer.scss';
import { FaFacebookSquare, FaTwitter, FaInstagram, FaTelegramPlane } from "react-icons/fa"
import { Link } from 'react-scroll';
// import styled from 'styled-components';
import Modal from './Modal';
import Privacy from './Privacy';
import Terms from './Terms';
// import { Link as RouterLink }  from 'react-router-dom';



const Footer = () => {
  const [modalShown, toggleModal] = React.useState(false);
  const [termsState, toggleTerms] = React.useState(false);
  return (
    <footer className="footer">
      <div className="container mx-auto">
        <div className="pagewrap">
          <div className="inner">
            <section className="links-sub">
              <div className="link-section">
                {/* <p>Legal</p> */}
                <ul>
                  <li className=""
                   onClick={() => { toggleTerms(!termsState); }}
                  >Terms and Condtions</li>
                  <Modal shown={termsState}
                    close={() => {
                      toggleTerms(false);
                    }} >
                    <Terms />
                  </Modal>
                  <li onClick={() => { toggleModal(!modalShown); }}
                  >Privacy Policy</li>
                  <Modal shown={modalShown}
                    close={() => {
                      toggleModal(false);
                    }} >
                    <Privacy />
                  </Modal>
                </ul>
              </div>
              <div className="link-section">
                {/* <p>Product</p> */}
                <ul>
                  <li><a href="https://token.easyshare.africa/Easyshare_SHARE_Whitepaper_Public.pdf" rel="noopener noreferrer" target="_blank">
                    White paper
                  </a></li>
                  <li><Link to="how" spy={true} smooth={true} offset={-70} duration={500}>How it Works</Link></li>
                </ul>
              </div>
              <div className="link-section">
                {/* <p>Resources</p> */}
                <ul>
                  <li><a rel="noopener noreferrer" target="_blank" href="https://medium.com/@easyshareafrica"> Blog</a></li>
                  <li><Link to="roadmap" spy={true} smooth={true} offset={-70} duration={500}>Roadmap</Link></li>
                </ul>
              </div>
              <div className="link-section">
                {/* <p>Contact </p> */}
                <ul>
                  <li><a rel="noopener noreferrer" target="_blank" href="https://help.easyshare.africa/">Help</a></li>
                  <li><a rel="noopener noreferrer" target="_blank" href="https://easyshare.africa/">Beta App</a></li>
                </ul>
              </div>
              <div className="link-section last form">
                <p>Subscribe to Easyshare</p>
                <form>
                  <input type="email" name="sub-email" id="sub-email" placeholder="youremail@gmail.com" defaultValue="" />
                  <button disabled="" type="submit">Subscribe</button>
                </form>
              </div>
            </section>
            <section className="social-copyright">
              <div className="links text-4xl grid gap-8 grid-flow-col">
                <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/easyshareafrica" >
                  <FaTwitter />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/easyshareafrica/">
                  <FaInstagram />
                </a>
                <a rel="noopener noreferrer" target="_blank" href="https://t.me/joinchat/lyV0Qi1mxnlkYTg0">
                  <FaTelegramPlane />
                </a>
                <a className="fb" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/easyshareafrica/">
                  <FaFacebookSquare />
                </a>
              </div>
              <p> © 2021. Easyshare</p>
            </section>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
