import React, { useState } from 'react'
import styled from 'styled-components'
import Logo from "../../images/logo.svg"
import { GiHamburgerMenu } from "react-icons/gi"
import { Link } from 'react-scroll';
import { Link as RouterLink }  from 'react-router-dom';

const NavUl = styled.ul`
  /* position: fixed; */

  li{
    position: relative;
    cursor: pointer;
    padding: .5rem 0;
    text-transform: capitalize;
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 2px;
      left: 0;
      right: 0;
      margin: .25rem auto;
      bottom: 0px;
      background-color: #33d5ba;
      transition: all ease-in-out .35s;
    }
    &:hover {
      color: #33d5ba;
      font-weight: 600;
      &::after {
        width: 100%;
        left: 0;
      }
    } 
  }
`;

const TransitionDiv = styled.div`

  display: none;
  &.show      { display: grid; }

&.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: "height, visibility";
  transition-duration: .35s;
  transition-timing-function: ease;
}

`;


const ButtonLi = styled.a`
  border-radius: .25rem;
  cursor: pointer;
  padding: .75rem 1.5rem;
  background-color: #33d5ba;
  color: white;
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const toggle = React.useCallback(() => {
    setOpen(v => !v);
  }, []);

  return (
    <nav className="fixed w-full top-0 z-20">
      <div className="flex justify-between items-center container mx-auto p-5">

        <RouterLink  exact="true" to="/">
        <img src={Logo} alt="EasyShare" className="transform duration-500 hover:scale-110 cursor-pointer" />
        </RouterLink>
        <NavUl className="hidden lg:grid place-items-center gap-6 lg:gap-8 grid-flow-col">
        <a href="https://easyshare.africa" rel="noopener noreferrer" target="_blank">
            <li>Beta App</li>
          </a>
        <a href="https://token.easyshare.africa/Easyshare_SHARE_Whitepaper_Public.pdf" rel="noopener noreferrer" target="_blank">
            <li>White paper</li>
          </a>
          <Link activeClass="active-nav" to="token" spy={true} smooth={true} offset={-70} duration={500}>
            <li>Token</li>
          </Link>
          <Link activeClass="active-nav" to="how" spy={true} smooth={true} offset={-70} duration={500}>
            <li>How it works</li>
          </Link>
          <Link activeClass="active-nav" to="roadmap" spy={true} smooth={true} offset={-70} duration={500}>
            <li>RoadMap</li>
          </Link>
          <Link activeClass="active-nav" to="about" spy={true} smooth={true} offset={-70} duration={500}>
            <li>About</li>
          </Link>
      
          {/* <Link activeClass="active-nav" spy={true} smooth={true} offset={-70} duration={500}> */}
          <ButtonLi className="animate-bounce"
           href="https://forms.gle/CvnRzdYSm2gYJagK6" rel="noopener noreferrer" target="_blank"
          >Join IDO Whitelist</ButtonLi>
          {/* </Link> */}
          {/* <Link activeClass="active-nav" to="ido" spy={true} smooth={true} offset={-70} duration={500}>
            <li>IDO</li>
          </Link> */}
        </NavUl>

        <div className="grid lg:hidden place-items-center border rounded cursor-pointer"
          onClick={toggle}>
          <GiHamburgerMenu className=" text-2xl m-1 rounded text-gray-700" />
        </div>
      </div>
      {/* Mobile Navigation */}
      <TransitionDiv className={`${open ? "show" : "collapsing"}`}>
        <NavUl className="grid mlg:hidden text-center place-items-center p-6 grid-flow-row border-t">
         <a href="https://easyshare.africa" rel="noopener noreferrer" target="_blank">
            <li>Beta App</li>
          </a>
         <a href="https://token.easyshare.africa/Easyshare_SHARE_Whitepaper_Public.pdf" rel="noopener noreferrer" target="_blank">
            <li>White paper</li>
          </a>
          <Link activeClass="active-nav" to="token" spy={true} smooth={true} offset={-70} duration={500}>
            <li onClick={toggle}>Token</li>
          </Link>
          <Link activeClass="active-nav" to="how" spy={true} smooth={true} offset={-70} duration={500}>
            <li onClick={toggle}>How it works</li>
          </Link>
          <Link activeClass="active-nav" to="roadmap" spy={true} smooth={true} offset={-70} duration={500}>
            <li onClick={toggle}>RoadMap</li>
          </Link>
          <Link activeClass="active-nav" to="about" spy={true} smooth={true} offset={-70} duration={500}>
            <li onClick={toggle}>About</li>
          </Link>
     
          {/* <Link activeClass="active-nav" spy={true} smooth={true} offset={-70} duration={500}> */}
          <ButtonLi className="py-3 px-12 w-full" href="https://forms.gle/CvnRzdYSm2gYJagK6" rel="noopener noreferrer" target="_blank"
          >Join IDO Whitelist</ButtonLi>
        </NavUl>
      </TransitionDiv>
    </nav>
  )
}

export default Navbar
