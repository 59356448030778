import React from 'react'
import styled from 'styled-components'


export const TheList = styled.ul`

  li{
    cursor: auto;
    a{
      color: #33d5bf !important;
      font-weight: 600;
      margin-bottom: 0 !important;
    }
    h4{
      font-weight: 500;
      font-size: 1.15rem;
    }
  }
`;

export const ModalDiv = styled.div`
  background-color: rgba(0,0,0, 0.5);
  display: grid;
  place-items: center;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  color: #303030;
  /* display: none; */

  & > div{
    background-color: #fff;
    max-width: 90%;

  }

`;

const Modal = ({ children, shown, close }) => {
  return shown ? (
    <ModalDiv className="py-24" onClick={() => {
      close();
    }}>
      <div className="w-11/12 sm:w-10/12 md:9/12 lg:w-8/12 p-4 md:p-10 rounded-lg"
        onClick={e => {
          e.stopPropagation();
        }}>
        {children}
      </div>
    </ModalDiv>
  ) : null
}

export default Modal
