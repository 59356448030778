import React from "react"
import Bitcoin from "../../images/asSeen/bitcoin.png"
import Guardian from "../../images/asSeen/guardian.png"
import Naira from "../../images/asSeen/naira.png"
import Pulse from "../../images/asSeen/pulse.png"
import TC from "../../images/asSeen/tc.png"
import Techpoint from "../../images/asSeen/techpoint.png"
import TheNation from "../../images/asSeen/theNation.png"
import Vanguard from "../../images/asSeen/vanguard.svg"
import { CenterLine } from "./About"

const Sponsors = [
  {
    image: TC,
    link: "https://techcabal.com/2021/06/08/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology/",
  },
  {
    image: Techpoint,
    link: "https://techpoint.africa/2021/06/09/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology/",
  },
  {
    image: Naira,
    link: "https://nairametrics.com/2021/06/08/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology-helping-african-consumers-send-and-receive-as-little-as-1-using-crypto-from-anywhere-in-the-world/",
  },
  {
    image: Bitcoin,
    link: "https://bitcoinke.io/2021/06/introducing-easyshare/",
  },
  {
    image: Pulse,
    link: "https://www.pulse.ng/business/easyshare-africa-launches-platform-using-safe-low-cost-cryptocurrency-technology/xkeqjjy",
  },
  {
    image: Vanguard,
    link: "https://www.vanguardngr.com/2021/06/easyshare-africa-appoints-prince-ugo-mike-enwerem-as-executive-director/",
  },
  {
    image: Guardian,
    link: " http://www.guardian.ng/news/mike-enwerem-emerges-easyshare-africas-executive-director/",
  },
  {
    image: TheNation,
    link: "https://thenationonlineng.net/easyshare-africa-launches-platform/",
  },
]

const AsSeenAs = () => {
  return (
    <div>
      <div className="text-center pt-10">
        <CenterLine className="">As Seen On</CenterLine>
      </div>
      <div className="container w-7/12 lg:w-8/12 max-w-4xl mx-auto py-16 grid place-items-center gap-x-20 gap-y-16 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {
          Sponsors.map(item => (
            <a key={item.image}
              href={item.link}
              target="_blank"
              className="transform cursor-pointer duration-500 hover:scale-110"
              rel="noopener noreferrer">
              <img src={item.image} alt={item.image} />
            </a>

          ))
        }
      </div>
    </div>
  )
}

export default AsSeenAs
