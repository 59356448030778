import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const state = {
  labels: [
    "10% - Total supply available for purchase at IDO whitelist sale",
    "10% - Total supply available for purchase at public sale listing",
    "10% - Reserved for $SHAREHodler Staking",
    "20% - Easyshare Community Reserve & Grants",
    "10% - Team allocation ",
    "10% - Marketing ",
    "5% - Advisors ",
    "5% - Partners",
    "20% - Liquidity "
  ],

  datasets: [
    {
      label: 'Total Supply',
      backgroundColor: [
        '#33d5ba',
        '#7ce2d1',
        '#b4ece3',
        '#ffd13c',
        '#fce59e',
        '#fbecbd',
        '#6dc4f2',
        '#fca39b',
        '#ff4d3d'
      ],
      hoverBackgroundColor: [
        '#501800',
        '#4B5000',
        '#175000',
        '#003350',
        '#003350',
        '#003350',
        '#003350',
        '#003350',
        '#35014F'
      ],
      data: [10, 10, 10, 20, 10, 10, 5, 5, 20],
      hoverOffset: 20
    }
  ]
}



export default class ChartFile extends React.Component {
  render() {
    return (
      <div className="max-w-3xl mx-auto">
        <Doughnut
          data={state}
          options={{
            title: {
              display: true,
              text: 'Average Rainfall per month',
              fontSize: 20
            },
            legend: {
              display: true,
              position: 'right'
            }
          }}
        />
      </div>
    );
  }
}