import React from 'react'
import { Heading } from './About'
import GoalsImg from "../../images/goals.svg"
import SDG1 from "../../images/SDGs.jpg"

import styled from 'styled-components'

const ATag = styled.a`
  color: blueviolet;
  font-weight: 600;
`;

const Goals = () => {
  return (
    <div className="container mx-auto py-16 flex items-center flex-col lg:flex-row">
      <div className="w-full lg:w-6/12 lg:pr-6 flex flex-row items-start">
        <img data-aos="zoom-out-left" data-aos-duration="1500"
          src={GoalsImg} alt="token" className="mr-8 hidden md:block" />
        <div>
          <Heading className="mt-6 md:mt-0">EasyShare and SDGs</Heading>
          <p>Easyshare makes a case for the unbanked/underbanked/financial inclusion and is
            therefore a contributor to eight of the <ATag target="_blank" rel="noopener noreferrer" href="https://sdgs.un.org/goals">UN’s Sustainable Development Goals — SDGs. </ATag>
            Our decentralised peer to peer network is popular across Africa where family and friends share and receive value among themselves with
            various stakes, reputations and trust which need to be gained and maintained.
            Bad actors lose their stakes, reputations and trust in the community and are dis-incentivised
            in various ways. We seek to replicate these age long African cultural practices on the
            blockchain.
          </p>
        </div>
      </div>
      <img className="lg:w-6/12 object-contain" src={SDG1} alt="SDGs" />
    </div>


  )
}

export default Goals
